import { Divider, Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import contentType from '../../../content/showcase/qap/intro.json';
import SupportedDevices from '../../../elements/supportedDevices';
import MetroSans from '../../../fonts/metro-sans/metroSans';
import colors from '../../../styles/colors';
import { defaultSectionWidth, emphasizedSizeText, pageTitle, smallText } from '../../../styles/dimensions';

interface Devices {
	desktop?: boolean;
	tablet?: boolean;
	mobile?: boolean;
}

interface Props {
	content: typeof contentType;
	devices: Devices;
	video: string;
	scaleMobile?: boolean;
}

interface ClassProps {
	scaleMobile?: boolean;
}

const IntroLayout = (props: Props) => {
	const { video, content, devices, scaleMobile } = props;

	const isSmall = useMediaQuery(useTheme().breakpoints.down(960));

	const classes = useClasses({ scaleMobile });

	return (
		<section>
			<div className={classes.container}>
				<video autoPlay muted loop playsInline className={classes.video}>
					<source src={video} type="video/mp4" />
				</video>
				<h1 className={classes.title}>{content.title}</h1>
			</div>

			<div className={classes.gridContainer}>
				<Grid
					container
					classes={{ root: classes.grid }}
					spacing={isSmall ? 0 : 2}
					data-scroll
					data-scroll-speed="2">
					<Grid item md={1}></Grid>

					<Grid item xs={12} sm={6} md={5}>
						<h4 className={classes.header}>{content.dashboard.title}</h4>
						<p className={classes.description}>{content.dashboard.description}</p>
					</Grid>

					<Grid item sm={1}></Grid>

					<Grid item xs={12} sm={5} md={4}>
						<h4 className={classes.header}>{content.services.title}</h4>
						<ul className={classes.description}>
							{content.services.items.map((item, key) => (
								<li key={key} className={classes.listItem}>
									{item}
								</li>
							))}
						</ul>
						<SupportedDevices {...devices} />
					</Grid>

					<Grid item md={1}></Grid>
				</Grid>
			</div>

			<div className={classes.dividerContainer} data-scroll data-scroll-speed="3">
				<Divider classes={{ root: classes.divider }} />
			</div>
		</section>
	);
};

export default IntroLayout;

const useClasses = makeStyles({
	container: {
		backgroundColor: 'black',
	},
	title: {
		...emphasizedSizeText,
		color: colors.white,
		position: 'absolute',
		top: '100vh',
		transform: 'translateY(-150%)',
		margin: 0,
		left: `calc(50vw - (${defaultSectionWidth} * (5 / 12)) + 8px)`,
		'@media (max-width: 1199px)': {
			left: `calc(8.33333333vw)`,
		},
		'@media (max-width: 959px)': {
			left: '0.22222222em',
		},
		'@media (max-width: 599px)': {
			transform: 'translateY(-200%)',
		},
		'@media (max-width: 25em)': {
			...pageTitle,
		},
	},
	video: {
		width: '100vw',
		height: '100vh',
		boxSizing: 'border-box',
		paddingTop: 'calc(7.625em + 2px)',
		'@media (max-width: 799px)': {
			transform: 'translateY(-5%)',
		},
		'@media (max-width: 599px)': {
			paddingTop: 'calc(3.75em + 2px)',
			transform: (props: ClassProps) => (props.scaleMobile ? 'scale(1.2) translateY(-5%)' : 'translateY(-5%)'),
		},
		'@media (max-width: 499px)': {
			transform: (props: ClassProps) =>
				props.scaleMobile ? 'scale(1.4) translateY(-7.5%)' : 'translateY(-7.5%)',
		},
		'@media (max-width: 399px)': {
			transform: (props: ClassProps) => (props.scaleMobile ? 'scale(1.6) translateY(-10%)' : 'translateY(-10%)'),
		},
	},
	gridContainer: {
		backgroundColor: colors.backgroundDarkGray,
		padding: '6.625em 0',
		marginTop: '-4px',
		'@media (max-width: 599px)': {
			padding: '4em 0',
			paddingTop: '3.5em',
		},
	},
	grid: {
		maxWidth: defaultSectionWidth,
		margin: '0em auto',
		'@media (max-width: 959px)': {
			padding: '0 1em',
		},
	},
	header: {
		color: colors.white,
	},
	description: {
		color: colors.careersJobGray,
		fontFamily: MetroSans.book,
	},
	list: {
		color: colors.careersJobGray,
		fontFamily: MetroSans.book,
		...smallText,
	},
	listItem: {
		fontFamily: MetroSans.book,
		...smallText,
	},
	dividerContainer: {
		padding: '0 1em',
	},
	divider: {
		backgroundColor: colors.lightDivider,
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		marginTop: '-2px',
	},
});
