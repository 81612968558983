import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

interface Props {
	desktop?: boolean;
	tablet?: boolean;
	mobile?: boolean;
}

const SupportedDevices = (props: Props) => {
	const { desktop, tablet, mobile } = props;

	const classes = useClasses();

	return (
		<div className={classes.devices} aria-label="Supported devices">
			{desktop && (
				<StaticImage
					src="../images/showcase/devices/desktop.svg"
					alt="Desktop"
					layout="constrained"
					loading="lazy"
					placeholder="tracedSVG"
					objectFit="contain"
				/>
			)}
			{tablet && (
				<StaticImage
					src="../images/showcase/devices/tablet.svg"
					alt="Tablet"
					layout="constrained"
					loading="lazy"
					placeholder="tracedSVG"
					objectFit="contain"
				/>
			)}
			{mobile && (
				<StaticImage
					src="../images/showcase/devices/mobile.svg"
					alt="Mobile"
					layout="constrained"
					loading="lazy"
					placeholder="tracedSVG"
					objectFit="contain"
				/>
			)}
		</div>
	);
};

export default SupportedDevices;

const useClasses = makeStyles({
	devices: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'baseline',
		marginTop: '3.625em',
		'&>div:not(:last-child)': {
			marginRight: '1.5em',
		},
	},
});
