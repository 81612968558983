import React from 'react';
import Layout from '../../components/layout';
import IntroLayout from '../../components/sections/showcase/introLayout';
import Views from '../../components/sections/showcase/terra/views';
import SEO from '../../components/seo';
import { descriptions, titles } from '../../consts/seo';
import content from '../../content/showcase/terra/intro.json';
// @ts-ignore
import video from '../../videos/terra.mp4';

const Terra = () => (
	<Layout>
		<SEO title={titles.showcase.terra} description={descriptions.showcase.terra} />

		<IntroLayout content={content} devices={{ desktop: true }} video={video} scaleMobile />
		<Views />
	</Layout>
);

export default Terra;
