import { Grid, makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import colors from '../../../../styles/colors';
import { defaultSectionWidth, smallText } from '../../../../styles/dimensions';
import content from '../../../../content/showcase/terra/views.json';
import Rigraf from '../../../../fonts/rigraf/regular/rigraf';
import MetroSans from '../../../../fonts/metro-sans/metroSans';
import { removeWidows } from 'string-remove-widows';

const Views = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<div className={classes.container}>
				<Grid container>
					<Grid item md={1}></Grid>

					<Grid item xs={12} md={10} data-scroll data-scroll-speed="2">
						<h3
							className={classes.subheader}
							dangerouslySetInnerHTML={{ __html: removeWidows(content.subheaderLink).res }}></h3>

						<StaticImage
							src="../../../../images/showcase/terra/overview.png"
							alt="Sprayer inspection overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							className={classes.sprayerInspectionsImage}
						/>
					</Grid>

					<Grid item md={1}></Grid>
				</Grid>

				<Grid container spacing={4} classes={{ root: classes.billingGrid }}>
					<Grid item xs={12} md={6} data-scroll data-scroll-speed="2">
						<div className={classes.goalContainer}>
							<h5 className={classes.goalTitle}>{content.goal.title}</h5>
							<p className={classes.goalDescription}>{content.goal.description}</p>
						</div>

						<StaticImage
							src="../../../../images/showcase/terra/invoice_overview.png"
							alt="Invoice overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
						/>
					</Grid>

					<Grid
						item
						xs={12}
						md={6}
						data-scroll
						data-scroll-speed="4"
						classes={{ root: classes.billingGridItem }}>
						<h3 className={classes.subheader}>{content.subheaderBilling}</h3>

						<StaticImage
							src="../../../../images/showcase/terra/new_invoice.png"
							alt="New invoice overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
						/>
					</Grid>
				</Grid>
			</div>

			<div data-scroll data-scroll-speed="1">
				<StaticImage
					src="../../../../images/showcase/terra/showcase_billing.jpg"
					alt="Terra billing and storage showcase"
					layout="fullWidth"
					loading="lazy"
					placeholder="blurred"
					objectFit="cover"
					objectPosition="50% 50%"
					className={classes.billingShowcaseImage}
				/>
			</div>

			<div className={classes.container}>
				<Grid container spacing={5} classes={{ root: classes.employeesGrid }}>
					<Grid item xs={12} md={5} data-scroll data-scroll-speed="2">
						<h3 className={classes.employeesSubheader}>{content.subheaderEmployees}</h3>

						<h5 className={classes.smallSubheader}>{content.scheduleOfInspections.title}</h5>
						<p className={classes.scheduleOfInspectionsDescription}>
							{content.scheduleOfInspections.description}
						</p>

						<StaticImage
							src="../../../../images/showcase/terra/schedule_tasks.png"
							alt="Schedule and tasks overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							className={classes.scheduleAndTaskImage}
						/>

						<h5 className={classes.smallSubheader}>{content.employeesToDo.title}</h5>
						<p className={classes.employeesToDoDescription}>{content.employeesToDo.description}</p>
					</Grid>

					<Grid item xs={12} md={7} data-scroll data-scroll-speed="4">
						<StaticImage
							src="../../../../images/showcase/terra/schedule_overview.png"
							alt="Examination schedule overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
							className={classes.scheduleOverviewImage}
						/>

						<StaticImage
							src="../../../../images/showcase/terra/other_tasks.png"
							alt="Other work tasks overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
						/>
					</Grid>
				</Grid>

				<div className={classes.employeesGridMobile} data-scroll data-scroll-speed="2">
					<h3 className={classes.employeesSubheader}>{content.subheaderEmployees}</h3>

					<h5 className={classes.smallSubheader}>{content.scheduleOfInspections.title}</h5>
					<p
						className={classes.scheduleOfInspectionsDescription}
						dangerouslySetInnerHTML={{
							__html: removeWidows(content.scheduleOfInspections.description).res,
						}}></p>
					<StaticImage
						src="../../../../images/showcase/terra/schedule_overview.png"
						alt="Examination schedule overview"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
						className={classes.scheduleOverviewImage}
					/>

					<h5 className={classes.smallSubheader}>{content.employeesToDo.title}</h5>
					<p className={classes.employeesToDoDescription}>{content.employeesToDo.description}</p>
					<StaticImage
						src="../../../../images/showcase/terra/other_tasks.png"
						alt="Other work tasks overview"
						layout="constrained"
						loading="lazy"
						placeholder="blurred"
					/>
				</div>

				<Grid container data-scroll data-scroll-speed="2" classes={{ root: classes.promotionGrid }}>
					<Grid item md={1}></Grid>

					<Grid item xs={12} md={10}>
						<div className={classes.promotionContainer}>
							<h3 className={classes.promotionHeader}>{content.subheaderPromotion}</h3>
							<p className={classes.promotionDescription}>{content.promotion}</p>
						</div>

						<StaticImage
							src="../../../../images/showcase/terra/promotion.png"
							alt="Promotion overview"
							layout="constrained"
							loading="lazy"
							placeholder="blurred"
						/>
					</Grid>

					<Grid item md={1}></Grid>
				</Grid>
			</div>

			<StaticImage
				src="../../../../images/showcase/terra/showcase_terra.jpg"
				alt="Terra showcase"
				layout="fullWidth"
				loading="lazy"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="50% 50%"
				className={classes.showcaseImage}
			/>
		</section>
	);
};

export default Views;

const useClasses = makeStyles({
	section: {
		backgroundColor: colors.backgroundDarkGray,
		'@media (max-width: 599px)': {
			paddingTop: '4em',
		},
	},
	container: {
		maxWidth: defaultSectionWidth,
		margin: '0 auto',
		'@media(max-width: 1231px)': {
			padding: '0 1em',
		},
	},
	subheader: {
		color: colors.white,
		marginTop: 0,
		marginBottom: '0.75em',
	},
	sprayerInspectionsImage: {
		'@media (max-width: 599px)': {
			marginBottom: '4em',
		},
	},
	goalContainer: {
		marginBottom: '4em',
		'@media (min-width: 600px)': {
			maxWidth: '66.66666667%',
			marginBottom: '4.75em',
		},
		'@media (min-width: 960px)': {
			position: 'relative',
			left: '16.66666667%',
			marginTop: '6.875em',
		},
	},
	goalTitle: {
		letterSpacing: '0.075em',
		color: colors.white,
		fontFamily: Rigraf.regular,
		marginTop: 0,
		marginBottom: '0.8em',
	},
	goalDescription: {
		...smallText,
		color: colors.careersJobGray,
		fontFamily: MetroSans.book,
	},
	billingGrid: {
		'@media (min-width: 600px)': {
			marginTop: '-10vh',
		},
	},
	billingGridItem: {
		'@media (min-width: 600px) and (max-width: 959px)': {
			marginTop: '-20vh',
		},
		'@media (max-width: 599px)': {
			marginTop: '2em',
		},
	},
	billingShowcaseImage: {
		marginTop: '4.25em',
		maxHeight: '100vh',
		'@media (max-width: 599px)': {
			marginTop: '4em',
			marginBottom: '4em',
		},
		'@media (min-width: 600px) and (max-width: 959px)': {
			marginTop: '-8vh',
		},
		'@media (min-width: 960px)': {
			marginTop: '-2vh',
		},
	},
	employeesGrid: {
		'@media (max-width: 959px)': {
			display: 'none',
		},
	},
	employeesGridMobile: {
		'@media (min-width: 960px)': {
			display: 'none',
		},
	},
	employeesSubheader: {
		color: colors.white,
		marginTop: 0,
		marginBottom: '1.75em',
		'@media (max-width: 599px)': {
			marginBottom: '0.83333333em',
		},
	},
	smallSubheader: {
		letterSpacing: '0.075em',
		color: colors.white,
		marginTop: 0,
		marginBottom: '0.8em',
	},
	scheduleOfInspectionsDescription: {
		fontFamily: MetroSans.book,
		...smallText,
		color: colors.careersJobGray,
		'@media (min-width: 960px)': {
			marginBottom: '6.875em',
			maxWidth: '80%',
		},
	},
	scheduleAndTaskImage: {
		marginBottom: '4em',
		'@media (min-width: 600px) and (max-width: 959px)': {
			marginBottom: '8em',
		},
		'@media (min-width: 960px)': {
			width: '143.356643357949%',
			position: 'relative',
			left: '-43.356643357949%',
			marginBottom: '9em',
		},
	},
	employeesToDoDescription: {
		fontFamily: MetroSans.book,
		...smallText,
		color: colors.careersJobGray,
		'@media (min-width: 960px)': {
			maxWidth: '80%',
		},
	},
	scheduleOverviewImage: {
		marginBottom: '4em',
		'@media (min-width: 960px)': {
			marginBottom: '9.25em',
		},
	},
	promotionGrid: {
		'@media (min-width: 600px)': {
			marginTop: '-12vh',
		},
		'@media (max-width: 599px)': {
			marginTop: '4em',
		},
	},
	promotionContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		marginBottom: '1.75em',
		'@media (max-width: 599px)': {
			flexDirection: 'column',
		},
	},
	promotionHeader: {
		color: colors.white,
		margin: 0,
	},
	promotionDescription: {
		fontFamily: MetroSans.book,
		...smallText,
		color: colors.careersJobGray,
		margin: 0,
		'@media (min-width: 600px)': {
			whiteSpace: 'pre-wrap',
		},
	},
	showcaseImage: {
		maxHeight: '100vh',
		marginTop: '2.25em',
		'@media (max-width: 599px)': {
			marginTop: '4em',
		},
	},
});
