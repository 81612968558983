export const descriptions = {
	home: 'Collective:mind | DEV is a tailor-made software development company. We are digital sapiens delivering solutions of impeccable quality. The difference.',
	careers: {
		main: 'Our company is always looking for fresh and creative minds. Check the open positions, submit your application and start a new chapter with us.',
		midBE: "We are looking for an experienced Backend Developer to help us #DeliverTheDifference to our clients. Are you our next team member? Let's see - send us your CV and Git Repository and we'll be in touch!",
		expFE: 'We are looking for a Frontend Developer who is motivated to combine the art of design with the art of programming. If this sounds interesting to you, hurry up and send us an application to join our team.',
		juniorFE:
			'We are looking for a Junior Frontend Developer! If you think that this position is ideal for you, send us an application and join our team!',
		systemAdministrator:
			'We are looking for an experienced System Admin to help us manage and maintain our servers and operating systems. Do you want to help #DeliverTheDifference with us?',
		reactDeveloper:
			"We are looking for an experienced React Developer to help us #DeliverTheDifference to our clients and partners. Are you our next team member? Let's see - send us your CV and Git Repository and we'll be in touch!",
		recruiter:
			"We are looking for a German speaking Recruiter to help us with active sourcing, job interviews and connecting suitable candidates with project requests. Are you our next team member? Let's see - send us your CV and we'll be in touch!",
		fullstack:
			"We are looking for an experienced Fullstack Developer with experience in Shopify to help us #DeliverTheDifference to our clients. Are you our next team member? Let's see - send us your CV and Git Repository and we'll be in touch!",
	},
	blog: 'Discover the digital world with us and read more about web and mobile development, app testing, UX/UI, and many more.',
	contact:
		'You want to talk with us? We are open to new business opportunities or questions. Send us a query and we will send you an e-mail as soon as possible.',
	privacyPolicy: 'We care about your privacy on the web. You can be sure that your privacy is safe with us.',
	services:
		'We are a young and very productive team with excellent results. Building a top-notch website and mobile apps with you.',
	showcase: {
		main: 'Here you can find apps that we build and develop for various businesses. Give us your trust and we will give you excellent apps.',
		enzkreis:
			'Discover the new app for the cleaner area in your leaving. You can report the garbage location or turn on a reminder to take your trash outside. How cool is that, huh?',
		QAP: 'QAP is the perfect app for reducing the workload on accounting employees. If you want to have everything at the moment, information needs to go digital. You are in right place, we can do it for you.',

		stabler:
			'Do you need an app that allows employees to find available tools with searching by warehouse and location proximity? Perfect! We have a Stabler app for you!',
		terra: 'If you need an application for storing and searching for information about the owner of an agricultural sprayer, you are in the right place.',
		THNG: 'With THNG you can safely vote for democratic decisions in politics, business, and society. Using this app you will reach people which you can invite to actively shape their future.',
		vestur: 'Do you want an additional feeling of safety when you are outside of your home? We present you the Vestur app which informs you about crimes committed in the area near the location you selected.',
	},
	team: 'A team that wants to push digital boundaries. Creative and agile minds that are collected in one, combining design and technology.',
	pageNotFound: 'Page not found',
};

export const titles = {
	pageNotFound: '404: Not found',
	blog: 'Blog',
	careers: {
		main: 'Careers',
		customerSupport: 'Careers - Customer Support',
		designer: 'Careers - UI/UX Designer',
		expFE: 'Careers - Experienced Frontend Developer',
		FE: 'Careers - Frontend Developer',
		juniorFE: 'Careers - Junior Frontend Developer',
		marketingSpecialist: 'Careers - Marketing Specialist',
		marketingSpecialistHr: 'Careers - Marketing Specialist/HR',
		midBE: 'Careers - Backend Developer',
		phpBE: 'Careers - PHP Developer',
		systemAdministrator: 'Careers - System Administrator',
		reactDeveloper: 'Careers - React Developer',
		fullstack: 'Careers - Fullstack Developer',
		recruiter: 'Careers - Recruiter',
	},
	contact: 'Contact',
	home: 'Home',
	privacyPolicy: 'Privacy Policy',
	services: 'Services',
	showcase: {
		main: 'Showcase',
		enzkreis: 'Showcase - Enzkreis',
		QAP: 'Showcase - QAP',
		stabler: 'Showcase - Stabler',
		terra: 'Showcase - Terra',
		THNG: 'Showcase - THNG',
		vestur: 'Showcase - Vestur',
	},
	team: 'Team',
};
